<template lang="pug">
.md-layout.text-center
  .md-layout-item.md-size-33.md-medium-size-50.md-small-size-70.md-xsmall-size-100
    form(@submit.prevent="submitLogin")
      login-card(header-color="primary")
        h4.title(slot="title") 登录
        md-button.md-simple.md-white(
          slot="buttons",
          :href="weworkLoginUrl",
          v-if="weworkLoginUrl"
        )
          img.mr-1(
            src="/img/wework.png",
            style="filter: brightness(10); width: 20px"
          )
          span 企业微信登录
        md-button.md-simple.md-success.md-lg(
          type="submit",
          slot="inputs",
          disabled,
          v-if="$route.query.state === 'wework_login'"
        )
          | 企业微信正在为你登录…
        template(v-else)
          md-field.md-form-group(slot="inputs")
            md-icon face
            label 用户名
            md-input(v-model="login")
          md-field.md-form-group(slot="inputs")
            md-icon lock_outline
            label 密码
            md-input(type="password", v-model="password")
          md-button.md-simple.md-accent.md-lg(type="submit", slot="footer")
            | 登录
</template>
<script>
import { LoginCard } from "@/components";
import { http } from "@/resources";
export default {
  components: {
    LoginCard
  },
  data() {
    const appId = process.env.VUE_APP_WEWORK_APP_ID || "";
    const agentId = process.env.VUE_APP_WEWORK_AGENT_ID || "";
    const homeUrl = process.env.VUE_APP_HOME_URL || "";
    return {
      login: null,
      password: null,
      weworkLoginUrl:
        appId && agentId
          ? `https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=${appId}&agentid=${agentId}&redirect_uri=${homeUrl}/login&state=wework_login`
          : ""
    };
  },
  methods: {
    async submitLogin() {
      try {
        const { login, password } = this;
        const {
          data: { user, token }
        } = await http.post("auth/login", {
          login,
          password
        });
        window.localStorage.setItem("token", token);
        this.$user = user;
        this.$router.push("/").catch(err => console.log(err.message));
      } catch (err) {
        console.error(err.message);
      }
    }
  },
  async created() {
    if (this.$route.query.state === "wework_login") {
      const {
        data: { user, token }
      } = await http.post("auth/login", {
        gateway: "wework",
        code: this.$route.query.code
      });
      window.localStorage.setItem("token", token);
      this.$user = user;
      this.$router.push("/").catch(err => console.log(err.message));
    }
  }
};
</script>

<style></style>
