

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { RoleResource } from "@/resources";
import { Role } from "@/resources/interfaces";
import { confirm } from "@/helpers/sweetAlert";

@Component({
  filters: {}
})
export default class RoleDetail extends Vue {
  @Prop({ default: false })
  add!: boolean;

  role: Partial<Role> = {
    id: "",
    permissions: []
  };
  async save() {
    this.role = await RoleResource.save(this.role);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.add) {
      this.$destroy();
      this.$router.replace(`/role/${this.role.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个角色",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await RoleResource.delete({ id: this.role.id });
    this.$router.back();
  }
  async mounted() {
    if (!this.add) {
      this.role = await RoleResource.get({ id: this.$route.params.id });
    }
  }
}
